.error-msg {

  color: red;
  font-weight: 500;
  position: relative;
  top: 5px;
  float: right;
  font-size: 13px;
}

.c-form {

  margin-bottom: 30px;
}



input[type="radio"] {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: transparent;
  vertical-align: middle;
  border: 2px solid black;
  border-radius: 50%;
  padding: 2px;
  margin: 5px 10px 3px 20px;

  
}

input[type="radio"]:checked {


border-color: rgba(0,0,0);
border-radius: 9999px;
border-width: 2px;
height: 16px;
width: 16px;
font-family: Karla,Arial,sans-serif;
font-size: 14px;
font-weight: 700;
text-transform: none;
line-height: 1.44;
padding: 2px;
background-color: black;
display: block;
  
}

input[type="radio"]:focus {

    outline: none;

}

input[type="radio"]:hover {
  
  
}

.margin-10 {

  margin-top: 10px;
}


.logo-display>label {

  border: 4px dotted black !important;
  width: 90px !important;
  height: 88px !important;
  min-width: 90px;
  padding: 30px;
  position: relative;
  top: 30px;
  cursor: pointer;
}


.other-photos-image {

  width: 250px;

}


.logo-display {

  margin-bottom: 50px;
}

.outerDiv {
  height: 400px;
  width: 100%;
  margin: 0px auto;
  padding: 5px;
  overflow: hidden;
}

.leftDiv {
  height: 400px;
  width: 48%;
  float: left;
}

.rightDiv {
  height: 400px;
  width: 48%;
  float: right;
  margin-top: 25px;
}

.overflow-none {

  overflow: hidden;
}

.list-1 {

  width: 100%;
  height: 100%;
}

.list-2 {

  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px
}

list-3 {

  height: 100%;
  outline: none;
  overflow-y: auto;
  position: relative;
}

.team-photo-upload-box > label {

  border-radius: 8px;
  border: 2px dashed rgb(0, 0, 0);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 220px;
  width: 500px;
  cursor: pointer;
}

.btn-a {

  position:fixed;
  margin: 0px;

}

.remove-btn:hover {

  color: rgba(150,159,161) !important;
  
}

.dropdown-c {

  --tw-border-opacity: 1;
  border-color: rgba(0,0,0,var(--tw-border-opacity));
  border-radius: 8px;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  --tw-text-opacity: 1;
  color: rgba(0,0,0,var(--tw-text-opacity));
  width: 100%;
  font-family: Karla,Arial,sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
}

.team-members {

  display: inline-block;
}

.team-member img {

  width: 50px;
  height: 50px;
}

.team-member span {

  position: relative;
  top:10px;

}

.category-disabled {

  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(98,98,98,var(--tw-text-opacity));
  font-family: Karla,Arial,sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.14;
  border: none;

  
}

.category-active {

  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  color: rgba(98,98,98,var(--tw-text-opacity));
  font-family: Karla,Arial,sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.14;
  border: none;

  
}

.category:hover {

  opacity: 0.5;
  border: none;
  
}

.category:focus {

  border: none;
  outline: none;
}

.dropdownDefaultBtn {

  background-color: white;
  color: black;
}

.dropdown-btn {

  background: none;
  border: none;
  outline: none;
}

.dropdown-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.dropdown-btn:focus {

  background: none !important;
  border: none;
  outline: none;
  
}
.menu-item:hover {

  background-color: rgba(242,245,250,var(--tw-bg-opacity));
}

.menu-items {

  border-radius: 10px;
}

.search-btn:focus {

  background: none !important;
  border: none;
  outline: none;
  
}

.search-input {

  border-radius: 100px;
  padding-right: 20px;
  padding-left: 20px;
  border:1px solid black !important;
}

.search-box .search-input:focus {
  outline: black;
  border: black;
}

input[type='search']:focus {
  
  border:1px solid black !important;

}



.image-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px; 
  margin-top: -32px;
  
}

.loader-text {
  position: relative;
  top: 10px;
  font-size: 15px;

}

.not-found-search {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  height: 0px;
  max-width: 80%;
  min-height: 80%;
}

.form input {

  border-color: rgba(0,0,0,var(--tw-border-opacity));
  border-radius: 8px;
  border-width: 2px;
  padding: 8px 16px;
}

.form input:focus {

  border-color: rgba(0,0,0,var(--tw-border-opacity));
  border-radius: 8px;
  border-width: 2px;
  padding: 8px 16px;
  outline: none;
}

.form label {

  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1;
}

.photo-input {

  --tw-border-opacity: 1;
  border-radius: 8px;
  border: 2px dashed rgba(0,0,0,var(--tw-border-opacity));
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 220px;
  cursor: pointer;
}

.badge-player {

  --tw-bg-opacity: 1;
  background-color: rgba(77,199,191,var(--tw-bg-opacity));
  padding: 4px 8px;
  border-radius: 9999px;
  color: rgba(255,255,255);
  font-family: Karla,Arial,sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.logo label {
  cursor: pointer;
}

.style-1 {

  border: 0px; clip: rect(0px, 0px, 0px, 0px); clip-path: inset(50%); height: 1px; margin: 0px -1px -1px 0px; overflow: hidden; padding: 0px; position: absolute; width: 1px; white-space: nowrap;
}

.search-libray-show:focus{

  outline: none;
  border-color: rgba(214,214,214);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color:rgba(214,214,214);
  --tw-ring-color:rgba(214,214,214);
}



.c-style-1 {

  margin: "0px !important";
  padding: "0px !important";
  border: "none !important";
  display: "block !important";
  background: "transparent !important";
  position: "relative !important";
  opacity: "1 !important";
}

.c-style-2 {

  border: "none !important";
  margin: "0px !important";
  padding: "0px !important";
  width: "1px !important";
  min-width: "100% !important";
  overflow: "hidden !important";
  display: "block !important";
  user-select: "none !important";
  transform: "translate(0px) !important";
  color-scheme: "light only !important";
  height: 24;
}

.c-style-3 {

  border: "none !important";
  display: "block !important";
  position: "absolute !important";
  height: "1px !important";
  top: "-1px !important";
  left: "0px !important";
  padding: "0px !important";
  margin: "0px !important";
  width: "100% !important";
  opacity: "0 !important";
  background: "transparent !important";
  pointer-events: "none !important";
  font-size: "16px !important";
}

.c-style-3:focus, .c-style-6:focus , .c-style-9:focus {

  border: none;
  outline: none;
}

.c-style-4 {

  margin: "0px !important";
  padding: "0px !important";
  border: "none !important";
  display: "block !important";
  background: "transparent !important";
  position: "relative !important";
  opacity: "1 !important";

}

.c-style-5 {

  border: "none !important";
  margin: "0px !important";
  padding: "0px !important";
  width: "1px !important";
  min-width: "100% !important";
  overflow: "hidden !important";
  display: "block !important";
  user-select: "none !important";
  transform: "translate(0px) !important";
  color-scheme: "light only !important";
  height: 24;
}

.c-style-6 {

  border: "none !important";
  display: "block !important";
  position: "absolute !important";
  height: "1px !important";
  top: "-1px !important";
  left: "0px !important";
  padding: "0px !important";
  margin: "0px !important";
  width: "100% !important";
  opacity: "0 !important";
  background: "transparent !important";
  pointer-events: "none !important";
  font-size: "16px !important";
  width: 100%;
}

.c-style-7 {

  margin: "0px !important";
  padding: "0px !important";
  border: "none !important";
  display: "block !important";
  background: "transparent !important";
  position: "relative !important";
  opacity: "1 !important";
}

.c-style-8 {

  border: "none !important";
  margin: "0px !important";
  padding: "0px !important";
  width: "1px !important";
  min-width: "100% !important";
  overflow: "hidden !important";
  display: "block !important";
  user-select: "none !important";
  transform: "translate(0px) !important";
  color-scheme: "light only !important";
  height: 24;
}

.c-style-9 {

  border: "none !important";
  display: "block !important";
  position: "absolute !important";
  height: "1px !important";
  top: "-1px !important";
  left: "0px !important";
  padding: "0px !important";
  margin: "0px !important";
  width: "100% !important";
  opacity: "0 !important";
  background: "transparent !important";
  pointer-events: "none !important";
  font-size: "16px !important";
  width: 100%;

}

.popup-invoice {

   width: 700px;;
}

.swal2-styled.swal2-confirm {

  border-radius: 9999px;
  padding: 5px 30px 5px 30px;
  font-size: 15px;
}

.swal2-styled:focus .swal2-confirm:focus {

  border: 1px solid black;
  outline: none;
  box-shadow: none;
}
.error-class {

  border: 2px solid red;
}

.Toastify__toast-container {

  width: auto !important;
}

.content-width-invoice {
  margin-right: 150px;
}

.list {
  user-select: none;
}

.item {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  margin: 8px;
  height: 60px;
  cursor: grab;
}

.items-invoice {

  width:500px;

}

.right-550 {

  position: absolute;
  right: 540px;
}

.b-border {

  border-bottom-left-radius: 48px!important;
  border-bottom-right-radius: 48px!important;
}


.index-StyledSortableItem_cls2__1ZPB1 .index-SortableItemHandle_cls1__Aa1li {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  transition-duration: .3s;
  }
.index-SortableItemHandle_cls2__1bWKI {
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 40px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 8px;
  position: absolute;
  left: -8px;
  top: 50%;
  width: 40px;
  z-index: 50;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
  }
 
  .index-StyledAddButton_cls2__8DiNK {
    --tw-border-opacity: 1;
    border-radius: 8px;
    border: 2px dashed rgba(0,0,0,var(--tw-border-opacity));
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    width: 88px;
    font-family: Mulish,Arial,sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.36;
}

.index-StyledAddButton_cls2__8DiNK:not(:disabled):hover {
  --tw-border-opacity: 1;
  border-color: rgba(150,159,161,var(--tw-border-opacity));
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(62,62,62,var(--tw-text-opacity));
}

.index-StyledSvg_cls2__nHIIG {
  display: inline-flex;
  flex-shrink: 0;
  fill: currentColor;
}

.info-icon {
  background-color: black;
  color: white;
  font-size: 15px;
  padding: 0px 4px 4px 3px;
}

.react-datepicker {

  border-radius: 16px;
  font-size: .8rem;
  width: 100%;
  border: 1px solid #aeaeae;
--tw-shadow: 0px 4px 24px rgba(0,0,0,0.12);
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);

  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  background-color: #fff;
  color: #000;
  border: 1px solid white !important;
  border-radius: .3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__month-container {
  width: 499px;
  }

.react-datepicker__header {

  background-color: white !important;
  border-style: none;
  padding: 16px;
}


.react-datepicker__day-names, .react-datepicker__week {
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  grid-template-columns: repeat(7,minmax(0,1fr)) !important;
  height: 48px !important;
}

.react-datepicker__month {
  padding-left: 16px;
  padding-right: 16px;
  }

  
 .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 30px;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; 
}

.react-datepicker__navigation{

  padding-top: 15px !important;
}

.react-datepicker__day-names {
  
  font-weight: bold;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: 700;
  font-size: 15px;
  font-size: 1.2rem;
  }

  

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: black !important;
  }

  .cancel-btn:hover {

    --tw-text-opacity: 1;
   color: rgba(150,159,161);
  }

  .cancel-btn {
    font-family: Mulish,Arial,sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding-left: 24px;
    padding-right: 24px;
    white-space: nowrap;
    font-family: Mulish, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.36;
  }

.resume-btn {

  color: rgba(77,199,191)!important;
}


.canceled:before {
  background-color: red;

}
  

.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-link__1TBC0:hover {
  --tw-text-opacity: 1;
  color: rgba(150,159,161);
  
}

.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-link__1TBC0 {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgba(98,98,98);
  font-family: Mulish,Arial,sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
  }


  .video-modal {
    width:80%;
  }

  .btn-s {

    width: 30%;

  }

  .video-modal-50 {
    width:48%;
  }


  .videoFrame {

    width: auto !important;
    height: 360px;
  }

  .form label {
  
     line-height: 1.5 !important;
     
  }

  .practice-id-tooltip { 
    
    width:500px;
    position: relative;
    right: 166px;
    
  }

  .download-invoice{
    padding-top: 10px;
    padding-bottom: 30px;
    background-color: transparent;
    border-width: 0;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    color: rgba(98,98,98);
    font-family: Mulish,Arial,sans-serif;
    font-size: 14px;
    font-weight: 700;
  }

  .download-invoice:hover {

    color: rgba(150,159,161);
  }

  .badge-player-1 {

    background-color: rgb(232, 81, 81);
    padding: 4px 8px;
    border-radius: 9999px;
    color: rgba(255,255,255);
    font-family: Karla,Arial,sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.2;
  }

  .badge-player-2 {

    background-color: rgb(234, 184, 8);
    padding: 4px 8px;
    border-radius: 9999px;
    color: rgba(255,255,255);
    font-family: Karla,Arial,sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.2;
  }

  .player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }
  
  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }

  body {
     background-color: black;
  }