@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Regular.eot');
  src: url('../fonts/Merriweather-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Merriweather-Regular.woff2') format('woff2'),
      url('../fonts/Merriweather-Regular.woff') format('woff'),
      url('../fonts/Merriweather-Regular.ttf') format('truetype'),
      url('../fonts/Merriweather-Regular.svg#Merriweather-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Regular.eot');
  src: url('../fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Mulish-Regular.woff2') format('woff2'),
      url('../fonts/Mulish-Regular.woff') format('woff'),
      url('../fonts/Mulish-Regular.ttf') format('truetype'),
      url('../fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Regular.eot');
  src: url('../fonts/Karla-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-Regular.woff2') format('woff2'),
      url('../fonts/Karla-Regular.woff') format('woff'),
      url('../fonts/Karla-Regular.ttf') format('truetype'),
      url('../fonts/Karla-Regular.svg#Karla-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  font-family: 'Mulish',sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather',sans-serif;
}
.title-welcome {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 10px;
}
.h-screen {
  height: 100vh;
}
.flex-col {
  flex-direction: column;
}
.brand-logo {
  overflow: hidden; 
  box-sizing: border-box; 
  display: inline-block; 
  position: relative; 
  width: 190px; 
  height: 30px;
}
.btn-sign-up {
  border-radius: 9999px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish,Arial,sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  background-color: #000;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.account-bg {
  display:inline-block;
  max-width:100%;
  overflow:hidden;
  position:relative;
  box-sizing:border-box;
  margin:0;
}
.account-bg-inner {
  box-sizing:border-box;
  display:block;
  max-width:100%;
}
.grid-cols-12 {
  grid-template-columns: repeat(12,minmax(0,1fr));
}
.gap-3 {
  gap: 24px ;
}
.gap-4px {
  gap: 4px;
}
.w-full {
  width: 100%;
}
.pt-3 {
  padding-top: 24px !important;
}
.h-full {
  height: 100%;
}
.grid {
  display: grid;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-right-bottom {
  background-position: 100% 100%;
}
.sign-up-holder {
  background-color: rgba(255,255,255,1);
  border-color: rgba(245,245,245,1);
  border-radius: 24px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0 0 transparent,0 0 transparent,0px 12px 32px rgba(0,0,0,0.04);
}
.forgot-link {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  color: rgba(98,98,98,1);
  font-family: Mulish,Arial,sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
}
.field-wel {
  flex-grow: 1;
  position: relative;
}
.sign-up-holder .form-control{
  border-color: rgba(0,0,0,1);
  border-radius: 8px;
  border-width: 2px;
  padding: 8px 16px;
  width: 100%;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  transition-duration: .3s;
  font-family: 'Karla',sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
  height: 56px;
}
.sign-up-holder .form-control::placeholder {
  color: #a0a8aa;
  opacity: 1; /* Firefox */
}

.sign-up-holder .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a0a8aa;
}

.sign-up-holder .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #a0a8aa;
}
.btn-sign-in {
  border-radius: 8px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish,Arial,sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  background-color: #000;
  color: #fff;
}
.col-span-12 {
  grid-column: span 12/span 12;
}
.col-span-7 {
  grid-column: span 7/span 7;
}
img {
  max-width: 100%;
}
.px-md-5 {
  padding-right: 3rem;
  padding-left: 3rem;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.align-self-end {
  align-self: flex-end;
}
.d-none {
  display: none;
}

@media (min-width: 576px) {
  .d-sm-block {
      display: block;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-4 {
      grid-column: span 4/span 4;
  }
}
@media (min-width: 1280px) {
  .xl\:col-start-2 {
      grid-column-start: 2;
  }
}

.icon-size-table {

  width: 30px;
  height: 30px;
}

@media screen and (max-width: 767px) {

  .brand-logo {
      width: 160px;
      vertical-align: middle;
  }

}

.button-bg-black {
  -webkit-appearance: button;
  background-color: #000 !important;
  background-image: none;
}

.MuiTableCell-head .MuiButton-root  {

  font-weight: 400 !important;
}

.MuiTableCell-head {

  font-weight: 400 !important;
}



@tailwind base;
@tailwind components;
@tailwind utilities